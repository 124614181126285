import { Option, OptionAmendInput, OptionDirection, OptionStatus, OptionType } from 'popcorn-js/options';
import { ImportExport } from 'popcorn-js';

export const OptionAmendInputFromOption = (option: Option): OptionAmendInput => {
    return {
        externalReference: option.externalReference,
        status: option.status,
        notionalAmount: option.notionalAmount,
        quoteAmount: option.quoteAmount,
        currencyPair: option.currencyPair,
        premium: option.premium,
        expiryDate: option.expiryDate,
        deliveryDate: option.deliveryDate,
        strikePrice: option.strikePrice,
        tradeDate: option.tradeDate,
        type: option.type,
        direction: option.direction,
        bank: option.bank,
        capturedSpotRate: option.capturedSpotRate,
        interbankRate: option.interbankRate,
        bankRate: option.bankRate,
        trader: option.trader,
        traderOrganisation: option.traderOrganisation,
        notes: option.notes,
        financialYear: option.financialYear,
        importExport: option.importExport,
        season: option.season,
        clientReference: option.clientReference,
        product: option.product,
        clientNotes: option.clientNotes,
        billingType: option.billingType,
        intermediaryMargin: option.intermediaryMargin,
        marginNotes: option.marginNotes,
        adminFee: option.adminFee,
        clientFee: option.clientFee,
        billedToBank: option.billedToBank,
    };
};

export const FieldIsEditable = (
    field: keyof Option,
    linkedEntities: boolean,
    transactionFieldsFilled?: boolean,
): boolean => {
    const aa: OptionAmendInput = {
        externalReference: 'string',
        status: OptionStatus.OPEN,
        notionalAmount: 1,
        quoteAmount: 1,
        currencyPair: 'string',
        premium: 1,
        expiryDate: new Date(),
        deliveryDate: new Date(),
        tradeDate: new Date(),
        type: OptionType.PUT,
        direction: OptionDirection.SELL,
        bank: 'string',
        interbankRate: 1,
        trader: 'string',
        traderOrganisation: 'string',
        financialYear: 'string',
        notes: 'string',
        importExport: ImportExport.EXPORT,
        season: 'string',
        clientReference: 'string',
        product: 'string',
        clientNotes: 'string',
        billingType: undefined,
        intermediaryMargin: 1,
        marginNotes: 'string',
        adminFee: 1,
    };

    const canEdit = field in aa;
    if (!linkedEntities) {
        return canEdit;
    }

    const fieldsNotEditableIfTradeValidButNoTransactionFees: Array<keyof Option> = [
        'billingType',
        'intermediaryMargin',
        'adminFee',
    ];
    const fieldsNotEditableIfLinked: Array<keyof Option> = [];

    const canEditLinked = !fieldsNotEditableIfLinked.includes(field);
    const canEditWithNoTransactionFees = !fieldsNotEditableIfTradeValidButNoTransactionFees.includes(field);

    if (!linkedEntities && transactionFieldsFilled === false) {
        return canEdit && canEditWithNoTransactionFees;
    }
    return canEdit && canEditLinked;
};

//
export const fieldLabels: Record<keyof Option, string> = {
    number: 'Number',
    externalReference: 'External\xa0Reference',
    status: 'Status',
    currencyPair: 'Currency\xa0Pair',
    premium: 'Premium',
    expiryDate: 'Expiry\xa0Date',
    deliveryDate: 'Delivery\xa0Date',
    strikePrice: 'Strike\xa0Price',
    tradeDate: 'Trade\xa0Date',
    type: 'Type',
    direction: 'Direction',
    importExport: 'Import\xa0Export',
    bank: 'Bank',
    interbankRate: 'Interbank\xa0Rate',
    bankRate: 'Bank\xa0Rate',
    trader: 'Trader',
    traderOrganisation: 'Trader\xa0Organisation',
    notes: 'Notes',
    notionalAmount: 'Notional\xa0Amount',
    putAmount: 'Put\xa0Amount',
    callAmount: 'Call\xa0Amount',
    financialYear: 'Financial\xa0Year',
    id: '',
    quoteAmount: 'Quote\xa0Amount',
    effectiveRate: 'Effective\xa0Rate',
    captureDate: 'Capture\xa0Date',
    processingOrgPartyCode: 'Processing\xa0Org',
    processingOrgName: '',
    transactionID: '',
    tradingPartyCode: 'Trading\xa0Party\xa0Code',
    tradingPartyName: '',
    capturedSpotRate: 'Captured\xa0Spot\xa0Rate',
    auditEntry: '',
    billingType: 'Billing\xa0Type',
    intermediaryMargin: 'Intermediary\xa0Margin',
    adminFee: 'Admin\xa0Fee',
    clientFee: 'Client\xa0Fee',
    billedToBank: 'Billed\xa0To\xa0Bank',
    marginNotes: 'Margin\xa0Notes',
    season: 'Season',
    product: 'Product',
    clientReference: 'Client\xa0Reference',
    clientNotes: 'Client\xa0Notes',
};

export enum State {
    nop = 0,
    error = 1,
    editingExisting = 2,
    loading = 3,
    showingHistory = 4,
}

export enum Event {
    init = State.nop,
    errorEvent = State.error,
    cancelEditExisting = State.nop,
    enterLoading = State.loading,
    doneLoading = State.nop,
    showHistory = State.showingHistory,
    exitHistory = State.nop,
}
